import React, { useEffect, useState } from "react";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import { useParams } from "react-router-dom";
import useMutationInstance from "../../hooks/useMutationInstance";

const URL = `${process.env.REACT_APP_BACKEND}/auth/verifyUser`;

const VerifyUser = () => {
  const { userId, verificationId } = useParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMesaage] = useState(null);

  const onError = (error) => {
    const errorResponse = error?.response?.data;
    // console.log(errorResponse);
    setErrorMessage(errorResponse?.message);
  };
  const onSuccess = (response) => {
    // console.log(response);
    setSuccessMesaage(response?.data?.message);
  };
  const { mutate, isPending } = useMutationInstance({
    onSuccess,
    onError,
  });

  useEffect(() => {
    if (!userId && !verificationId) {
      setErrorMessage("Invalid Verification Link");
    }
    mutate({ url: URL, data: { userId, verificationId } });
  }, []);

  return (
    <NonProtectedLayout>
      {isPending && <p className="text-center">Loading...</p>}
      {successMessage && (
        <p className="text-center text-green-600">{successMessage}</p>
      )}
      {errorMessage && (
        <p className="text-center text-red-600">{errorMessage}</p>
      )}
    </NonProtectedLayout>
  );
};

export default VerifyUser;
