import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const footerSections = [
  {
    name: "QUICK LINKS",
    id: Math.random(),
    links: [
      { label: "Taara", link: "/taara", id: Math.random() },
      { label: "About Us", link: "/about", id: Math.random() },
      { label: "Pitch Training", link: "/pitch-training", id: Math.random() },
      { label: "Pricing", link: "/pricing", id: Math.random() },
    ],
  },
  {
    name: "COMMUNITY",
    id: Math.random(),
    links: [
      { label: "Assessment", link: "/assessment", id: Math.random() },
      { label: "Ambassador", link: "/ambassador", id: Math.random() },
      { label: "Blog", link: "/blog", id: Math.random() },
      { label: "FAQs", link: "/faq", id: Math.random() },
    ],
  },
];

const footerLinks = [
  { label: "Taara", link: "/taara" },
  { label: "About", link: "/about" },
  { label: "Blog", link: "/blog" },
  { label: "FAQ", link: "/faq" },
];

const Footer = () => {
  return (
    <>
      <div className="footerComponent">
        <div className="topFooterArea">
          <div className="leftTopFooter">
            <div className="footerLogo"></div>
            <div className="footerLogoText">
              Empowering women to lead with confidence and courage—discover your
              potential with Taara Quest.
            </div>
          </div>
          <div className="rightTopFooter">
            {footerSections.map((footerSection) => (
              <div className="rightTopFooterList" key={footerSection.id}>
                <div className="rightTopFooterListHeading">
                  {footerSection.name}
                </div>
                {footerSection.links.map((footerLink) => (
                  <Link
                    className="rightTopFooterListElement"
                    to={footerLink.link}
                    key={footerLink.id}
                  >
                    {footerLink.label}
                  </Link>
                ))}
              </div>
            ))}

            {/* <div className="rightTopFooterList">
						<div className="rightTopFooterListHeading">HELP CENTER</div>
						<div className="rightTopFooterListElement">Find on Store</div>
						<div className="rightTopFooterListElement">How to Install?</div>
						<div className="rightTopFooterListElement">Why Us?</div>
						<div className="rightTopFooterListElement">FAQs</div>
					</div> */}
            <div className="rightTopFooterList">
              <div className="rightTopFooterListHeading">CONTACT INFO</div>
              <div className="rightTopFooterListElement">hello@taara.quest</div>
              <div className="rightTopFooterListElement">
                Gohliser Str. 16, 04105 Leipzig, Germany
              </div>
              <Link to="/privacy-policy">
                <div className="rightTopFooterListElement">Privacy Policy</div>
              </Link>
              <Link to="/terms">
                <div className="rightTopFooterListElement">
                  Terms of Service
                </div>
              </Link>

              <div className="socialFooterIcons">
                <a href="https://www.tiktok.com/@taara_quest?is_from_webapp=1&sender_device=pc">
                  <i className="fa-brands fa-tiktok"></i>
                </a>
                <a href="https://www.instagram.com/taara.quest/">
                  <i className="fa-brands fa-square-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/company/taara-quest">
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <hr className="footerDivider" />
        <br></br>
        <div className="bottomFooterArea">
          © 2024 apergo UG | All rights reserved | Imprint | Privacy & Cookie
          Policy
        </div>
      </div>
      <div className="mobileFooterComponent">
        <div className="flex flex-col gap-16">
          {footerSections.map((footerSection) => (
            <div className="rightTopFooterList" key={footerSection.id}>
              <div className="rightTopFooterListHeading text-center">
                {footerSection.name}
              </div>
              {footerSection.links.map((footerLink) => (
                <Link
                  className="rightTopFooterListElement text-center"
                  to={footerLink.link}
                  key={footerLink.id}
                >
                  {footerLink.label}
                </Link>
              ))}
            </div>
          ))}

          <div className="rightTopFooterList">
            <div className="rightTopFooterListHeading text-center">COMPANY</div>
            <p className="rightTopFooterListElement text-center">
              Email: hello@taara.quest
            </p>
            <Link
              className="rightTopFooterListElement text-center"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
            <Link className="rightTopFooterListElement text-center" to="/terms">
              Terms of Service
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-8 mb-4">
          <div className="mobileFooterIcons">
            <a href="https://www.tiktok.com/@taara_quest?is_from_webapp=1&sender_device=pc">
              <i className="fa-brands fa-tiktok"></i>
            </a>
            <a href="https://www.instagram.com/taara.quest/">
              <i className="fa-brands fa-square-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/taara-quest">
              <i className="fa-brands fa-linkedin"></i>
            </a>
          </div>
          <p>Taara Quest @2024</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
