import React from "react";
import ProtectedRoutesLayout from "../../../layout/ProtectedRoutesLayout/ProtectedRoutesLayout";
import PrimaryHeading from "../../../components/primitives/PrimaryHeading";
import PrimaryButton from "../../../components/button/PrimaryButton";

const Billing = () => {
  return (
    <ProtectedRoutesLayout>
      <div className="flex flex-col gap-[88px] px-24">
        <PrimaryHeading
          heading="Thanks for giving us the change to Help you grow"
          className="text-center"
        />
        <div className="flex justify-center">
          <PrimaryButton text="Go To Billing" className="bg-primaryBlue" />
        </div>
      </div>
    </ProtectedRoutesLayout>
  );
};

export default Billing;
