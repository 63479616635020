import React, { useEffect, useState } from "react";
import ProtectedRoutesLayout from "../../../layout/ProtectedRoutesLayout/ProtectedRoutesLayout";
import PrimaryHeading from "../../../components/primitives/PrimaryHeading";
import PrimaryButton from "../../../components/button/PrimaryButton";
import Table from "../../../components/primitives/CustomTable/CustomTable";
import CustomModal from "../../../components/primitives/CustomModal/CustomModal";
import { useQueryInstance } from "../../../hooks/useQueryInstance";
import { useSelector } from "react-redux";
import { useQueryAuthInstance } from "../../../hooks/useQueryAuthInstance.jsx";
import clsxm from "../../../utility/clsxm.js";
import Spinner from "../../../components/Spinner/Spinner.jsx";
import AddCreditsModal from "../../../components/primitives/CustomModal/AddCreditsModal/AddCreditsModal.jsx";

const CustomQuest = () => {
  const auth = useSelector((state) => state.auth);

  const [noQuestsCreatedYet, setNoQuestsCreatedYet] = useState(true);

  const [openCustomQuestCreateModal, setOpenCustomQuestCreateModal] =
    useState(false);

  const [openAddCreditsModal, setOpenAddCreditsModal] = useState(false);

  const handleOpenCustomQuestCreateModal = () =>
    setOpenCustomQuestCreateModal(true);
  const handleCloseCustomQuestCreateModal = () =>
    setOpenCustomQuestCreateModal(false);

  const handleQuestCreatedYet = () => setNoQuestsCreatedYet(false);

  const handleOpenCreditsModal = () => setOpenAddCreditsModal(true);
  const handleCloseCreditsModal = () => setOpenAddCreditsModal(false);

  const { data, error, isFetching } = useQueryInstance(
    "custom-scenarios-appearances",
    "/custom/scenarios/appearances"
  );

  const {
    data: customQuests,
    error: customQuestsError,
    isFetching: isCustomQuestsFetching,
  } = useQueryAuthInstance(
    "get-custom-quests",
    `/custom/scenarios/user/${auth.user?.id}`,
    !!auth?.user?.id,
    auth.token
  );

  useEffect(() => {
    if (customQuests && customQuests?.data.length > 0) {
      setNoQuestsCreatedYet(false);
    }
  }, [customQuests]);

  // console.log("//customQuests", customQuests);
  return (
    <ProtectedRoutesLayout>
      <div className="flex flex-col gap-8 pt-[30px] px-[60px]">
        <PrimaryHeading heading="Create Your Own Quest" />
        {noQuestsCreatedYet && !isFetching ? (
          <div className="flex flex-col gap-12 items-center">
            <div className="flex flex-col">
              <CustomParagraph
                text=" Start now and roleplay challenging conversations and get feedback and
          tips to prepare as well a individual debriefing session coaching you
          on learning and growing opportunities."
              />
              <CustomParagraph
                text=" You will have the next encounter in your own hands and come well
          prepared."
              />
            </div>
          </div>
        ) : (
          isFetching && <Spinner />
        )}

        {!isFetching && (
          <>
            <Table tableData={customQuests?.data} />
            <div className="flex justify-center">
              <button
                className={clsxm(
                  "flex items-center border-none w-fit object-contain bg-primaryPink text-white text-lg text-center rounded-[10px] cursor-pointer h-[46px] px-5 hover:scale-105 transition-transform"
                  // disable ? "bg-[#AEAEAE] cursor-not-allowed" : "",
                  // className
                )}
                onClick={handleOpenCustomQuestCreateModal}
              >
                Create new scenario
              </button>
            </div>
          </>
        )}
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-6">
              <div className="col-span-2">Monthly conversations remaining</div>
              <div className="col-span-4">2/10</div>
            </div>
            <div className="grid grid-cols-6">
              <div className="col-span-2">
                Additional conversations remaining:
              </div>
              <div className="col-span-4">4</div>
            </div>
          </div>
          <div className="flex justify-center">
            <PrimaryButton
              text="Get More Credits"
              onClick={handleOpenCreditsModal}
            />
          </div>
        </div>
      </div>
      <CustomModal
        appearances={data?.data || []}
        isOpen={openCustomQuestCreateModal}
        closeModalAction={handleCloseCustomQuestCreateModal}
      />
      <AddCreditsModal
        isOpen={openAddCreditsModal}
        closeModalAction={handleCloseCreditsModal}
      />
    </ProtectedRoutesLayout>
  );
};

const CustomParagraph = ({ text }) => (
  <p className="text-base text-[#424242] w-[65%] leading-10 font-300 font-nohemi">
    {text}
  </p>
);

export default CustomQuest;
